<template>
  <div class="container" style="margin-top:100px;margin-bottom:50px;">
    <div class="row">
      <div class="col-lg-12">
        <h1 class="subheader" style="font-size:20px;"><strong>Hireclap Sitemap</strong></h1>
      </div>
      <div class="col-lg-12 col-md-12">
        <h2 class="panel-title m-0" style="font-size:18px !important;">Jobs by location</h2>
        <div class="parentContent col">
          <div class="childContent row">
            <ul class="list-unstyled col-lg-4 col-md-4 pb-0 pt-0 mb-0" v-for="job in jobs_by_cities" :key="job.id">
              <li><a class="flink text-dark" :href="job.search_url" target="_blank">{{job.name}}</a></li>
              <!-- <li v-for="job in jobs_by_cities" :key="job.id"><a class="flink text-dark" :href="job.search_url" target="_blank">{{job.name}}</a></li> -->
              <!-- <li><a href="/jobs/city/jobs-in-mumbai" class="list" data-toggle="toggle" target="_blank">Jobs in Mumbai</a></li>
              <li><a href="/jobs/city/jobs-in-chennai" class="list" data-toggle="toggle" target="_blank">Jobs in Chennai</a></li>
              <li><a href="/jobs/city/jobs-in-hydrabad" class="list" data-toggle="toggle" target="_blank">Jobs in Hyderabad</a></li>
              <li><a href="/jobs/city/jobs-in-delhi" class="list" data-toggle="toggle" target="_blank">Jobs in Delhi</a></li>
              <li><a href="/jobs/city/jobs-in-kolkata" class="list" data-toggle="toggle" target="_blank">Jobs in Kolkata</a></li>
              <li><a href="/jobs/city/jobs-in-ahamedabad" class="list" data-toggle="toggle" target="_blank">Jobs in Ahamedabad</a></li> -->
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <h2 class="panel-title m-0" style="font-size:18px !important;">Jobs by courses</h2>
        <div class="parentContent">
          <div class="childContent row">
            <ul class="list-unstyled col-lg-4 col-md-4 pb-0 pt-0 mb-0" v-for="job in jobs_by_courses" :key="job.id">
              <li><a class="flink text-dark" :href="job.search_url" target="_blank">{{job.name}}</a></li> 
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <h2 class="panel-title m-0" style="font-size:18px !important;">Jobs by category</h2>
        <div class="parentContent">
          <div class="childContent row">
            <ul class="list-unstyled col-lg-4 col-md-4 pb-0 pt-0 mb-0" v-for="job in jobs_by_category" :key="job.id">
              <li><a class="flink text-dark" :href="job.search_url" target="_blank">{{job.name}}</a></li> 
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <h2 class="panel-title m-0" style="font-size:18px !important;">Jobs by company</h2>
        <div class="parentContent">
          <div class="childContent row">
            <ul class="list-unstyled col-lg-4 col-md-4 pb-0 pt-0 mb-0" v-for="job in jobs_by_company" :key="job.id">
              <li><a class="flink text-dark" :href="job.search_url" target="_blank">{{job.name}}</a></li> 
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <h2 class="panel-title m-0" style="font-size:18px !important;">Jobs by roles</h2>
        <div class="parentContent">
          <div class="childContent row">
            <ul class="list-unstyled col-lg-4 col-md-4 pb-0 pt-0 mb-0" v-for="job in jobs_by_roles" :key="job.id">
              <li><a class="flink text-dark" :href="job.search_url" target="_blank">{{job.name}}</a></li> 
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <h3 class="panel-title m-0" style="font-size:18px !important;">Jobseeker</h3>
        <div class="parentContent">
          <div class="childContent row">
            <ul class="list-unstyled col-md-6">
              <!-- <li><a href="/jobseeker/register" class="list" data-toggle="toggle" target="_blank">Register</a></li> -->
              <li><a href="/" class="list" data-toggle="toggle" target="_blank">Home</a></li>
              <li><a href="/jobseeker/myprofile" class="list" data-toggle="toggle" target="_blank">Dasboard</a></li>
              <li><a href="/jobs" class="list" data-toggle="toggle" target="_blank">Jobs</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <h4 class="panel-title m-0">Employer</h4>
        <div class="parentContent">
          <div class="childContent row">
            <ul class="list-unstyled col-lg-3">
              <li><a href="/recruit" class="list" data-toggle="toggle" target="_blank">Home</a></li>
              <li><a href="/recruit/register" class="list" data-toggle="toggle" target="_blank">Register</a></li>
              <li><a href="/recruit/login" class="list" data-toggle="toggle" target="_blank">Login</a></li>
            </ul>
            <ul class="list-unstyled col-lg-3">
              <li><a href="/recruit/client-job-dashboard" class="list" data-toggle="toggle" target="_blank">Dashboard</a></li>
              <li><a href="/recruit/post-job" class="list" data-toggle="toggle" target="_blank">Post Job</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <h4 class="panel-title m-0">Common</h4>
        <div class="parentContent">
          <div class="childContent row">
            <ul class="list-unstyled col-lg-6">
              <li><a href="/terms" class="list" data-toggle="toggle" target="_blank">Terms & Conditions</a></li>
              <li><a href="/privacy" class="list" data-toggle="toggle" target="_blank">Privacy Policy</a></li>
              <li><a href="/unsubscribe" class="list" data-toggle="toggle" target="_blank">Unsubscribe</a></li>
            </ul>
            <ul class="list-unstyled col-lg-6"></ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Actions from '../libraries/apiActions.js'
export default({
  name: "SitemapPage",
  data(){
    return{
      action: new Actions(),
      jobs_by_cities:[],
      jobs_by_courses:[],
      jobs_by_branches:[],
      jobs_by_roles:[],
      jobs_by_skills:[],
      jobs_by_company:[],
      jobs_by_category:[]
    }
  },
  beforeMount(){
    this.action.getJobsByURL().then(res => {
      res.url.forEach(data => {
        let static_id = data.static_id;
        switch(static_id){
          case 1 :this.jobs_by_cities.push(data);
                  break;
          case 2 :this.jobs_by_courses.push(data);
                  break;
          case 3 :this.jobs_by_roles.push(data);
                  break;
          case 4 :this.jobs_by_category.push(data);
                  break;
          case 5 :this.jobs_by_company.push(data);
                  break;
          case 6 :this.jobs_by_branches.push(data);
                  break;
          case 7 :this.jobs_by_skills.push(data);
                  break;
          default : console.log('field not exit');
        }
      });
    });
  },
})
</script>

<style scoped>
.panel-title {background-color: rgba(0,0,0,0.5);color: #fff;padding: 10px 15px;font-size: 18px;}.parentContent{font-size: 12px;color: #333;border: 1px solid #dcdcdc;width: auto;padding: 25px 16px;background-color: #fff;}.childContent{font-size: 14px;color: #333;width: 100%;}.childContent ul{padding-left : 18px;}li{padding: 0px;list-style: none;}.list{color: #000;font-size: 13px;}
</style>